// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7qE4Ded2hJ3_RAOWZVwZxmcFs-xPuNLY",
  authDomain: "animeway-c3ffe.firebaseapp.com",
  projectId: "animeway-c3ffe",
  storageBucket: "animeway-c3ffe.appspot.com",
  messagingSenderId: "485130562828",
  appId: "1:485130562828:web:3d19315006cbdbca872d29",
  measurementId: "G-5NQ98QXKVX",
  storageBucket:"gs://anime-way-yeye"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth =  getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleProvider = new GoogleAuthProvider();
