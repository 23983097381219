import Container from 'react-bootstrap/Container';
import { MyNavbar } from './MyNavbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { useContext } from 'react';
import { UserDataContext, UserContext, AppDataContext } from './ContextStuff';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./Firebase";
import { SEO } from "./SEO";

export function ForgotPassword(){
    const [validated, setValidated] = useState(false);
    const currentUser = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleSubmit = (event) => {
        // event.stopPropagation();
        event.preventDefault();
        setValidated(true);
    
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
          sendPasswordResetEmail(auth, event.target[0].value).then(()=>{
            setSuccessMessage('Password Reset Email Sent');
            setErrorMessage(null);
          }).catch((error)=>{
            let message = 'Error';
            if(error.code === 'auth/user-not-found'){
                message += ' User Not Found';
            }
            setErrorMessage(message);
            setSuccessMessage(null);
          })
        }else{
          setErrorMessage(null);
        }
      }
    
    const seo = (<SEO
        title='ForgotPassword'
        description='Forgot Password? Reset your AnimeWay account password here'
        name='AnimeWay'
        type='website'
     />);

    if(currentUser){
        return(
            <Container className='mw-100 container-fluid d-flex flex-column' style={{height: "100vh"}}>
                {seo}
                <MyNavbar/>
                <div style={{flex: 1, justifyContent: "center", alignItems: 'center'}} className="d-flex row">
                    <div className="col-lg-6 mx-auto px-sm-5">
                        <div>
                            You are logged in already, how did you forget your password exactly... 
                        </div>
                    </div>
                </div>
            </Container>
        )
    }

    return (
        <Container className='mw-100 container-fluid d-flex flex-column' style={{height: "100vh"}}>
            {seo}
            <MyNavbar/>
            <div style={{flex: 1, justifyContent: "center", alignItems: 'center'}} className="d-flex row">
                <div className="col-lg-6 mx-auto px-sm-5">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {successMessage && (<div className="alert alert-success mt-3">
                            {successMessage}
                        </div>)}
                        {errorMessage && (<div className="alert alert-danger mt-3">
                            {errorMessage}
                        </div>)}
                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required/>
                            <Form.Control.Feedback type="invalid">
                            Invalid email
                            </Form.Control.Feedback>
                            {/* <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                            </Form.Text> */}
                        </Form.Group>
                        <Button variant="info" type="submit">
                            Forgot Password
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
}