import { auth , googleProvider} from "./Firebase";
import { signInWithPopup, signOut, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { MyNavbar } from "./MyNavbar";
import { Link, useNavigate } from 'react-router-dom'
import { FcGoogle } from 'react-icons/fc';
import { VerifyEmail } from "./VerifyEmail";
import { UserDataContext, UserContext, AppDataContext } from './ContextStuff';
import { SEO } from "./SEO";



export const Login = (props) => {
  const currentUser = useContext(UserContext);


  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();  

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if(auth.currentUser.emailVerified){
        navigate("/");
      }else{
        navigate("/verifyemail");
      }
      
    } catch (err){
      if(err.code === 'auth/wrong-password'){
        setErrorMessage('Incorrect password');
      }else if(err.code === 'auth/user-not-found'){
        setErrorMessage('No user with that email exists');
      }else{
        setErrorMessage(null);
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth,googleProvider);
      navigate("/");
    } catch (err){
      setErrorMessage(`Error logging in to Google (${err.code})`);
    }
  };
  

  const handleSubmit = (event) => {
    // event.stopPropagation();
    event.preventDefault();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      signIn(event.target[0].value, event.target[1].value); 
    }else{
      setErrorMessage(null);
    }
  }

  const seo = (<SEO
    title='Login'
    description='Login to AnimeWay to start tracking your anime watching progress.'
    name='AnimeWay'
    type='website'
 />);

  if(currentUser !== null){
    if(currentUser.emailVerified){
      return(
        <Container className='mw-100 container-fluid d-flex flex-column' style={{height: "100vh"}}>
          {seo}
          <MyNavbar/>
          <div style={{flex: 1, justifyContent: "center", alignItems: "center"}} className="d-flex row">
            <div className="col-lg-6 mx-auto px-sm-5">
              <span></span>
              <p><b>email:</b> {currentUser.email}</p>
              <p>You are already logged in... why are you navigating here?</p>
            </div>
          </div>
        </Container>
      );
    }else{
      return(<VerifyEmail />);
    }
  }else{
    return (
      <Container className='mw-100 container-fluid d-flex flex-column' style={{height: "100vh"}}>
        {seo}
        <MyNavbar/>
        <div style={{flex: 1, justifyContent: "center", alignItems: "center"}} className="d-flex row">
          <div className="col-lg-6 mx-auto px-sm-5" style={{maxWidth: "600px"}}>
            <Button type="button" className="btn-light" onClick={signInWithGoogle}><FcGoogle size={'30'}/> Login with Google</Button>
            <p className="my-3">Or</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              {errorMessage && (<div className="alert alert-danger mt-3">
                {errorMessage}
              </div>)}
              <Form.Group className="mb-1" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" required/>
                <Form.Control.Feedback type="invalid">
                  Invalid email
                </Form.Control.Feedback>
                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" required/>
                <Form.Control.Feedback type="invalid">
                  Invalid Password
                </Form.Control.Feedback>
              </Form.Group>

              <Button variant="info" type="submit">
                Sign In
              </Button>
              <Link className="px-3 link-info" to="/register">Register</Link>
              <Link className="link-info" to="/forgotpassword">Forgot Password?</Link>
            </Form>
          </div>
        </div>
      </Container>
    );
  }
};
