import Container from 'react-bootstrap/Container';
import { MyNavbar } from './MyNavbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { useContext } from 'react';
import { UserDataContext, UserContext, AppDataContext } from './ContextStuff';
import { updatePassword } from "firebase/auth";
import { SEO } from './SEO';

export function ChangePassword() {
    const [validated, setValidated] = useState(false);
    const currentUser = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const navigate = useNavigate();  

    const handleSubmit = (event) => {
        // event.stopPropagation();
        event.preventDefault();
        setValidated(true);
    
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
          updatePassword(currentUser, event.target[0].value).then(()=>{
            setSuccessMessage('Password Reset Successful');
            setErrorMessage(null);
          }).catch((error)=>{
            setErrorMessage('Could not reset password. ' + error.message);
            setSuccessMessage(null);
          })
        }else{
          setErrorMessage(null);
        }
      }

    const seo = (<SEO
        title='ChangePassword'
        description='Change your password for you AnimeWay account'
        name='AnimeWay'
        type='website'
     />);

    if(!currentUser){
        return(
            <Container className='mw-100 container-fluid d-flex flex-column' style={{height: "100vh"}}>
                {seo}
                <MyNavbar/>
                <div style={{flex: 1, justifyContent: "center", alignItems: 'center'}} className="d-flex row">
                    <div className="col-lg-6 mx-auto px-sm-5">
                        <div>
                            You are not currently logged in...
                        </div>
                        <div>
                            <button className='btn btn-info' onClick={()=>{
                                navigate("/login");
                            }}>Login Here</button>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }

      
    return (
        <Container className='mw-100 container-fluid d-flex flex-column' style={{height: "100vh"}}>
            {seo}
            <MyNavbar/>
            <div style={{flex: 1, justifyContent: "center", alignItems: 'center'}} className="d-flex row">
                <div className="col-lg-6 mx-auto px-sm-5">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {successMessage && (<div className="alert alert-success mt-3">
                            {successMessage}
                        </div>)}
                        {errorMessage && (<div className="alert alert-danger mt-3">
                            {errorMessage}
                        </div>)}
                        <Form.Group className="mb-3" controlId="formNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="New Password" required/>
                            <Form.Control.Feedback type="invalid">
                            Invalid Password
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="info" type="submit">
                            Change Password
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
}

    